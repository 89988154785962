import React from "react"
import { css, useTheme } from "@emotion/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Embed } from "../components/embed"
import { mediaQuery } from "../utils/mediaQuery"
import { Carousel } from "../components/carousel"

export const query = graphql`
  query PageQuery($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      isImageAlbum
      contentful_id
      seoTitle
      articlePageTitle
      articlePageDescription {
        articlePageDescription
      }
      articlePageVideoEmbed
      articlePageImages {
        file {
          url
        }
      }
    }
  }
`

const Article = ({ data }) => {
  const theme = useTheme()

  if (!data) return null

  return (
    <Layout>
      <Seo title={data.contentfulArticle.seoTitle} />
      <div
        css={css`
          background: white;
          color: black;
          ${mediaQuery[1]} {
            margin-top: ${theme.heights.header};
          }
        `}
      >
        {data.contentfulArticle.isImageAlbum &&
          data.contentfulArticle.articlePageImages?.length && (
            <div
              css={css`
                background: black;
                padding: 20px 0;
              `}
            >
              <Carousel
                numberOfItems={data.contentfulArticle.articlePageImages.length}
              >
                {data.contentfulArticle.articlePageImages.map(
                  (image, index) => (
                    <img
                      key={index}
                      alt={`${
                        data.contentfulArticle.articlePageTitle + " " + index
                      }`}
                      css={css`
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                      `}
                      src={image.file.url}
                    />
                  )
                )}
              </Carousel>
            </div>
          )}
        {!data.contentfulArticle.isImageAlbum &&
          data.contentfulArticle.articlePageVideoEmbed && (
            <div
              css={css`
                background: black;
              `}
            >
              <Embed
                title={data.contentfulArticle.articlePageTitle}
                src={data.contentfulArticle.articlePageVideoEmbed}
              />
            </div>
          )}
        <h1
          css={css`
            text-align: center;
          `}
        >
          {data.contentfulArticle.articlePageTitle}
        </h1>
        <p
          css={css`
            padding: 0 5% 5% 5%;

            ${mediaQuery[2]} {
              padding: 0 12.5% 5% 12.5%;
            }
          `}
        >
          {data.contentfulArticle.articlePageDescription.articlePageDescription}
        </p>
      </div>
    </Layout>
  )
}

export default Article
