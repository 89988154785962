import { css } from "@emotion/react"
import React, { useState } from "react"
import logo from "../images/tujo-logo-white.png"
import { mediaQuery } from "../utils/mediaQuery"

const containerStyles = fullWidth => css`
  ${!fullWidth && "margin: 0 auto;"}

  ${mediaQuery[1]} {
    width: ${fullWidth ? "100%" : "70%"};
  }
`

const iframeStyles = isLoading => css`
  opacity: ${isLoading ? "0" : "1"};
  transition: opacity 0.5s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const videoContainerStyles = css`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
`

const loadingSpinnerStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  margin-top: 20px;
`

export const Embed = ({ title, src, fullWidth }) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div css={containerStyles(fullWidth)}>
      <div css={videoContainerStyles}>
        {isLoading ? (
          <div css={loadingSpinnerStyle}>
            <img
              src={logo}
              alt="Loading"
              css={css`
                height: auto;
                width: 100%;
              `}
            />
          </div>
        ) : null}
        <iframe
          css={iframeStyles(isLoading)}
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onLoad={() => setIsLoading(false)}
          title={title}
        ></iframe>
      </div>
    </div>
  )
}
